.phrase-edit-icon {
    float: right;
    margin-bottom: 0.5em !important;
}

.phrase-edit-icon.left {
    float: left;
    margin-left: 1em;
}

.phrase-edit-icon:hover {
    cursor: pointer;
    color: green;
    color: var(--primary-color);
}

.phrase-label-loader {
    margin-top: -0.25em !important;
    margin-left: 0.25em !important;
}

.phrase-input {
    color: var(--text-primary-color) !important;
}

.phrase-text-area {
    color: var(--text-primary-color) !important;
}

.phrase-checkbox {
    width: 100%;
}

.phrase-header {
    color: var(--text-primary-color) !important;
}

.phrase-header.middle .content {
    vertical-align: middle !important;
}

.phrase-label {
    color: var(--text-primary-color) !important;
}

.phrase-dropdown {}

.phrase-button {}

.phrase-radio {}

.phrase-paragraph {
    color: var(--text-primary-color) !important;
}

.phrase-message {
    color: var(--text-primary-color) !important;
}

.phrase-span {
    color: var(--text-primary-color) !important;
}