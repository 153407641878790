.notifications-container {
    height: 100%;
    width: 100%;
    right: 0;
    background-color: #fff;
    background-color: var(--background-color);
    -webkit-transition: margin-right 0.4s, transform 0.4s; /* For Safari 3.1 to 6.0 */
    transition: margin-right 0.4s, transform 0.4s;
    display: flex;
    flex-direction: column;
}

    .notifications-container.mobile {
        position: fixed;
        z-index: 2000;
        overflow: hidden;
    }

    .notifications-container.hide {
        transform: translateX(-16em);
    }

    .notifications-container.collapse {
        margin-right: -100%;
    }