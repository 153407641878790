.login-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(#fff, #ddd);
    background-image: linear-gradient(var(--primary-color-light), var(--primary-color-dark));
}

.login-header-mobile {
    background-color: #fff;
    padding-left: 3em;
    padding-right: 3em;
    padding-top: 2em;
    padding-bottom: 2em;
}

.login-content-mobile {
    background-image: linear-gradient(#fff, #ddd);
    background-image: linear-gradient(var(--primary-color-light), var(--primary-color-dark));
    padding: 1em;
    flex: 1;
}

.login-logo-container {
    background-color: var(--logo-background-color) !important;
    color: #fff !important;
    height: 10em;
}

.login-logo {
    max-height: 8em;
    margin: auto;
}

.login-title {
    color: #fff !important;
    color: var(--primary-on-color) !important;
    margin-bottom: 0 !important;
}

.login-description {
    color: #fff;
    color: var(--primary-on-color) !important;
}

.login-header {
    color: var(--surface-color-dark) !important;
}

.login-form-container {
    background-color: var(--surface-color) !important;
}

.login-form {
    margin-top: 1.5em;
    flex: 1;
}

.login-button {
    background-color: var(--primary-color) !important;
    color: #000 !important;
    color: var(--primary-on-color) !important;
}

    .login-button:hover {
        background-color: var(--primary-color-dark) !important;
        color: var(--primary-on-color-dark) !important;
    }

.login-button.mobile {
    background-color: var(--primary-on-color) !important;
    color: #000 !important;
    color: var(--primary-color) !important;
}

    .login-button.mobile:hover {
        background-color: var(--surface-color-dark) !important;
        color: var(--surface-on-color-dark) !important;
    }

.login-loader {
    margin-top: 3em !important;
}

.login-loader-container {
    height: 100vh;
}