.module-wrapper-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.module-wrapper-content-container {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.module-wrapper-sub-module-container {
    width: 100%;
}

.module-wrapper-menu-container {
    background-color: #E5EEF1;
    background-color: var(--primary-color-light) !important;
}