.sidebar-container {
    height: 100%;
    width: 20em;
    background-color: #ffffff;
    -webkit-transition: margin-left 0.4s, transform 0.4s; /* For Safari 3.1 to 6.0 */
    transition: margin-left 0.4s, transform 0.4s;
    display: flex;
    flex-direction: column;
}

.sidebar-container.mobile {
    position: fixed;
    z-index: 2000;
    overflow:hidden;
}

.sidebar-container.hide {
    transform: translateX(-20em);
}

.sidebar-container.collapse {
    margin-left:-20em;
}

.sidebar-header-container {
    flex-shrink: 0;
    height: 5em;
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    border-bottom: solid 1px var(--border-color);
    padding: 1em;
}

.sidebar-header-container.mobile {
    height: 4em;
}

.sidebar-logo {
    max-width: 10em;
    height: 3em;
}

.sidebar-menu-container {
    overflow: hidden;
    padding-top: 1em;
    height: 100%;
    border-right: solid 1px var(--border-color);
}

.sidebar-menu {
    height: 100%;
    text-align: left !important;
    background-color: transparent !important;
    overflow-y: auto;
    border-radius: 0 !important;
    border: 0 !important;
    box-shadow: none !important;
    width: 100% !important;
}

.sidebar-menu-item {
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-placeholder-color) !important;
    cursor: pointer;
}

.sidebar-menu-item .phrase-paragraph {
    color: var(--text-placeholder-color) !important;
}

.sidebar-menu-item.active, .sidebar-menu-item.active .phrase-paragraph {
    color: var(--primary-color-dark) !important;
}

    .sidebar-menu-item.child.active .phrase-paragraph, .sidebar-menu-item.single.active .phrase-paragraph {
        font-weight: bold;
    }

.sidebar-menu-multi-item.active {
    background-color: #3093ae !important;
    background-color: var(--primary-color) !important;
    color: #fff !important;
    color: var(--primary-on-color) !important;
}

.sidebar-menu-multi-item:focus {
    border:0;
    outline: none !important;
}

.sidebar-menu-item-caret {
    margin-right: -0.5em !important;
    overflow: hidden;
}
.sidebar-menu-subitem {
    color: #ffffff !important;
    color: var(--primary-on-color-dark) !important;
    border-radius: 0 !important;
    white-space: nowrap;
    text-align: left !important;
    margin-left: 0em !important;
    opacity: 0.6;
}

.sidebar-menu-subitem-icon {
    display: inline !important;
    padding-right: 0.5em !important;
    padding-left: 0.35em !important;
}

.sidebar-menu-subitem-paragraph {
    margin-left: 0.8em !important;
}

.subitem-noIcon {
    margin-left: 3.2em !important;
}

.sidebar-menu-subitem.active {
    text-decoration: bold;
    background-color: #3093ae !important;
    background-color: var(--primary-color) !important;
    color: #fff !important;
    color: var(--primary-on-color) !important;
    opacity: 1;
}

.sidebar-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    flex-shrink: 0;
    height: 3em;
    width: 16em;
    overflow: hidden;
    border-top: solid 1px #ffffff !important;
    border-top: solid 1px rgba(255, 255, 255, 0.3) !important;
}

.sidebar-footer .left {
    font-size: 0.9em;
    float: left;
    margin-left: 1em;
    margin-top: 1em;
    margin-right: 0.5em;
}

.sidebar-footer .right {
    float: left;
    margin-top: 0.1em;
}

.sidebar-footer .logo {
    height: 2em;
}

.sidebar-footer-mobile-logout {
    background-color: transparent !important;
    color: var(--primary-on-color) !important;
    padding: 0.6em !important;
    margin:auto !important;
    white-space: nowrap;
}

.sidebar-footer-divider{
    margin-top:0 !important;
    margin-bottom:0 !important;
}