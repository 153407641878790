:root {
    --background-color: #e5e5e5;
    --background-on-color: #000000;
    --text-primary-color: #3A3A3A;
    --text-secondary-color: #565656;
    --text-tertiary-color: #565656;
    --text-placeholder-color: #868E96;
    --border-color: #DFE3E9;
    --primary-color: #2E92AB;
    --primary-on-color: #FFFFFF;
    --primary-color-light: #7BC6D7;
    --primary-on-color-light: #05647A;
    --primary-color-dark: #006E97;
    --primary-on-color-dark: #FFFFFF;
    --secondary-color: #E62276;
    --secondary-on-color: #FFFFFF;
    --secondary-color-light: #F174A8;
    --secondary-on-color-light: #AF004A;
    --secondary-color-dark: #AF004A;
    --secondary-on-color-dark: #FFFFFF;
    --surface-color: #EEEEEE;
    --surface-on-color: #1B1B1B;
    --surface-color-light: #FFFFFF;
    --surface-on-color-light: #1B1B1B;
    --surface-color-dark: #959595;
    --surface-on-color-dark: #FFFFFF;
    --error-color: #F44336;
    --error-on-color: #FFFFFF;
    --error-color-light: #F44336;
    --error-on-color-light: #FFFFFF;
    --warning-color: #FF9800;
    --warning-on-color: #FFFFFF;
    --warning-color-light: #FFC164;
    --warning-on-color-light: #000000;
    --success-color: #4CAF50;
    --success-on-color: #FFFFFF;
    --success-color-light: #A9E2AC;
    --success-on-color-light: #000000;
    --logo-background-color: #fff;
    --material-gray-1: #F2F2F2;
    --material-gray-2: #E0E0E0;
    --material-gray-3: #BDBDBD;
    --material-gray-4: #828282;
    --material-gray-5: #4F4F4F;
    --material-gray-6: #333333;
}