.module-menu {
    width: 100% !important;
    height: 100%;
    border-radius: 0 !important;
    border: 0 !important;
    background-color: var(--surface-color-light) !important;
    color: var(--surface-on-color-light) !important;
}

.module-menu-item {
    color: #fff !important;
    color: var(--surface-on-color) !important;
    border-radius: 0 !important;
}

.module-menu-item.active {
    background-color: #2c3e50 !important;
    background-color: var(--surface-color-dark) !important;
    color: #fff !important;
    color: var(--surface-on-color-dark) !important;
}

.module-titlebar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    height: 100%;
    border-radius: 0 !important;
    border: 0 !important;
    background-color: #fff !important;
    background-color: var(--surface-color) !important;
    margin: 0 !important;
}

.module-titlebar-item {
    color: #000 !important;
    color: var(--surface-on-color-light) !important;
}

.module-title-mobile {
    font-size: 1.1em !important;
    padding-left: 0 !important;
    font-weight: bold;
    text-align: center;
}