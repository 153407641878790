.date-button:focus {
    background-color: #c0c1c2 !important;
}

.Filter-List {
    background-color: var(--surface-color);
    overflow: visible;
    width: 13em;
    margin-left: 0.2em;
    margin-right: 0;
    height: 100%;
}

.Filter-search {
    display: inline-block;
    width: calc(100% - 0.5em);
    margin-left: 0.25em !important;
    margin-top: 0.25em !important;
}

.filter-search-icon::before {
    color: var(--secondary-on-color);
}

.filter-dropdown {
    width: 3.4em;
    margin-left: 0.25em;
    margin-right: 0.25em;
    margin-top: 0.25em;
}

.filter-dropdown-buttons {
    border-radius: 0.3em;
    height: 30px;
}

.filter-dropdown-button-left,
.filter-dropdown-button-left:hover {
    padding: 0 !important;
    width: 2em;
    height: inherit;
    background-color: var(--material-gray-2) !important;
}

.filter-dropdown-button-right,
.filter-dropdown-button-right:hover {
    width: 1em;
    height: inherit;
    padding: 0 !important;
    background-color: var(--material-gray-3) !important;
}

/*.filter-dropdown-sort-label {

}*/

.filter-dropdown-sort-label-icon {
    margin: 0 !important;
}

.filter-dropdown-sort-button {
    background-color: var(--primary-color) !important;
    color: var(--primary-on-color) !important;
}

.filter-dropdown-filter-cell {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.filter-list-choices {
    overflow-x: hidden;
    overflow-y: auto;
    margin-left: 0.25em !important;
    margin-top: 0.5em !important;
    margin-right: 0.25em !important;
    width: 12.5em !important;
    flex-direction: column !important;
}

.filter-list-choices-row {
    color: var(--primary-on-color-light) !important;

    border-radius: 0.25em;
    margin-bottom: 0.25em;
    margin-right: 0.25em;
    height: 2.5em;
    padding: 0 !important;
    display: flex !important;
    align-items: center !important;
}

.filter-list-choices-row.active-row {
    background-color: var(--primary-color) !important;
    color: var(--primary-on-color) !important;
}

.filter-list-cell-status {
    padding-left: 0.6em !important;
}

.filter-list-cell-status-icon {
    margin: 0 !important;
}

.filter-list-cell-name {
    white-space: nowrap;
    padding-left: 0.6em !important;
}

.filter-list-choices::-webkit-scrollbar {
    width: 0.25em;
    border-right: 20em;
}

.sidesheet-tab {
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.sidesheet-tab .menu {
    padding: 1em 1em 0 1em !important;
    margin: 0 !important;
    background-color: #fff !important;
}

.sidesheet-tab .tab {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
}