html {
    -webkit-overflow-scrolling: touch;
}

body {
    background-color: #fff;
    background-color: var(--background-color) !important;
    color: var(--text-primary-color);
}

.site-container {
    height: 100vh;
    overflow: hidden;
    margin: 0;
    display: flex;
    box-sizing: border-box;
    background-color: #fff;
    background-color: var(--background-color);
}

.main-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;
    flex: 1;
}

.navbar-height {
    height: 4em;
    z-index:10;
}

.module-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
}

.module-container.mobile {
    margin-bottom: 5em !important;
}

.module-titlebar-container {
    flex-shrink: 0;
    height: 3em;
}

.module-titlebar-container-mobile {
    flex-shrink: 0;
    height: 4em;
}

.module-main-container {
    display: flex;
    flex: 1;
}

.module-menu-container {
    width: 15em;
    height: 100%;
}

.module-content-container {
    flex: 1;
}

.module-segment-container {
    background-color: #fff;
    display: flex;
    flex-grow: 1;
    overflow-y: auto;
    --width: 100%;
}

.error-message-wrapper {
    --position: fixed;
}

.nowrap {
    white-space: nowrap;
}

.ui.vertical.menu .item > i.icon.left {
    float: none;
    margin: 0em 0.35714286em 0em 0em;
}

.page-dimmer-overlay {
    position: fixed;
    background-color: #000000;
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    width: 100%;
    height: 100%;
    z-index: 1900;
}

.page-dimmer-overlay.active {
    opacity: 100;
    cursor: pointer;
}

.page-dimmer-overlay.inactive {
    opacity: 0;
    pointer-events: none;
    touch-action: none;
    cursor: auto;
}

.ui.form input[type="number"] {
    padding: 0 !important;
    text-align: center !important;
}