.alert-button {
    background-color: #e64848 !important;
    background-color: var(--error-color) !important;
    color: #fff !important;
    color: var(--error-on-color);
}

.alert-button:hover {
    background-color: var(--error-color-light) !important;
    background-color: var(--error-on-color-light) !important;
}

.primary-button {
    background-color: blue !important;
    background-color: var(--primary-color) !important;
    color: #fff !important;
    color: var(--primary-on-color) !important;
}

.primary-button:hover {
    background-color: var(--primary-color-light) !important;
    color: var(--primary-on-color-light) !important;
}

.secondary-button {
    background-color: grey !important;
    background-color: var(--secondary-color) !important;
    color: #fff !important;
    color: var(--secondary-on-color) !important;
}

.secondary-button:hover {
    background-color: var(--secondary-color-light) !important;
    color: var(--secondary-on-color-light) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}

i.icon, i.icons {
    font-size: 1.24em;
}