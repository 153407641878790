.navbar-container {
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: none !important;
    height: 5em;
    border-bottom: solid 1px var(--material-gray-1);
}

.navbar-container.mobile {
    height: 4em;
}

.navbar-icons-container {
    flex: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-avatar {
    background-color: var(--primary-color) !important;
    color: var(--primary-on-color) !important;
}

.navbar-menu {
    width: 100% !important;
    height: 100%;
    border-radius: 0 !important;
    border: 0 !important;
    background-color: #e5eef1 !important;
    background-color: var(--primary-color-light) !important;
    color: var(--primary-on-color-light) !important;
    background-color: #fff !important;
}

.navbar-menu-item {
    color: #fff !important;
    color: var(--primary-on-color-light) !important;
    border-radius: 0 !important;
}

.navbar-menu-item.active {
    --background-color: #2c3e50 !important;
    --background-color: var(--primary-color) !important;
    color: #fff !important;
    color: var(--primary-color) !important;
    border: 1px solid #dfdfdf85 !important;
}

.navbar-menu-item:hover {
    --background-color: #2c3e50 !important;
    --background-color: var(--primary-color) !important;
    color: #fff !important;
    color: var(--primary-color) !important;
    --border: 1px solid #dfdfdf85 !important;
}

.navbar-dropdown-menu.active {
    --background-color: #2c3e50 !important;
    --background-color: var(--primary-color) !important;
    color: #fff !important;
    color: var(--primary-color) !important;
    --border: 1px solid #dfdfdf85 !important;
}

.navbar-dropdown-menu:hover {
    --background-color: #2c3e50 !important;
    --background-color: var(--primary-color) !important;
    color: #fff !important;
    color: var(--primary-color) !important;
    --border: 1px solid #dfdfdf85 !important;
}

.navbar-mobile {
    margin-top: 0em !important;
    border-radius: 0em !important;
}

.navbar-dock {
    position: absolute;
    bottom: 0px;
    height: 5em;
}

.dock-item.active {
    background-color: rgba(0, 0, 0, 0) !important;
    color: rgba(1, 1, 1, 1) !important;
    color: var(--primary-color) !important;
}

.dock-item {
    display: block !important;
    text-align: center;
    padding-right: 0 !important;
    padding-left: 0 !important;
    margin: 0;
    position: relative;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.5) !important;
}

.dock-item-icon {
    display: block !important;
    width: auto !important;
    margin-bottom: 0.4em !important;
    margin-right: 0 !important;
}

.navbar-notification-popup {
    width: 30em !important;
}